$profile-image-width: 20rem;


//COLOURS
$color-primary: #A0C1D1;
$color-primary-light: #EAF4F4;
$color-primary-dark: #B0413E;

$color-grey-light: #f7f7f7;


$color-grey-dark: #5E5B52;
$color-grey-dark-2: #63585E;
$color-grey-dark-3: #484349;

$color-white: #fff;
$color-black: #141115;

//FONT SIZE
$default-font-size: 1.6rem;
$font-size-small: 1.3rem;
$font-size-medium: 2rem;
$font-size-big: 2.5rem;
$font-size-huge: 4rem;
$font-size-extrasmall: 1rem;

//FONT FAMILY
$primary-font: 'Lato', sans-serif;
$secondary-font: 'Montserrat', sans-serif;

// font-family: 'Lato', sans-serif;
// font-family: 'Montserrat', sans-serif;

//FONT WEIGHT
$font-weight-big: 900;
$font-weight-medium: 700;
$font-weight-small: 400;