.skills {
  &__container {
    width: 100%;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    text-align: center;
    padding-top: 10rem;
    margin: 0 3%;
    
    &-items {
      width: 25%;
      display: flex;
      flex-direction: column;
      padding: 7rem;
      align-items: center;
      
    }

    &-name {
      font-size: $default-font-size;
    }

    & svg {
      width: 8rem;
      height: 8rem;
    }
  }
}

@media screen and (max-width: 800px) {
  .skills {
    &__list {
      margin: 0;
      &-items {
        padding: 3rem;
      }

      & svg {
        width: 6rem;
        height: 6rem;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .skills {
    &__list {
      &-items {
        padding: 2rem;
        width: 50%;
      }

      &-name {
        color: $color-black;
        font-size: $font-size-small;
      }

      & svg {
        width: 4rem;
        height: 4rem;
      }
    }
  }
}