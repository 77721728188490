.about {

  &__box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 80%;
  }

  &__image {
    display: flex;
    flex-direction: column;
    
    &--photo {
      border-radius: 5rem;
      box-shadow: 0 0 3rem rgba(0, 0, 0, 0.35);
      width: $profile-image-width;
      margin: auto;
    }
  }

  &__description {
    margin: auto 3rem;
  }


  &__text {
    margin: auto;
    text-align: left;
  }
}

@media screen and (max-width: 800px) {
  .about {
    
    &__box {
      flex-direction: column;
      width: 90%;
    }

    &__image {
      max-width: 40rem;
      margin: 0 auto;

      &--photo {
        border-radius: 50%;
      }
    }

    &__description {
      margin: 0;
    }

    &__text {
      padding: 3rem 0;
    }
  }
}

@media screen and (max-width: 500px) {
  .about {

    &__image {
      &--photo {
      width: 15rem;
      }
    }
  }
}