.nav {
  display: flex;
  flex-direction: column;
  left: 50%;
  transform: translateX(-50%);
  bottom: .2rem;
  z-index: 2;
  position: fixed;
  background: rgba($color-black, 0.1);
  border-radius: 3.5rem;
  backdrop-filter: blur(15px);
  width: max-content;
  display: block;
  padding: 0 2rem;
  animation: moveInBottomNav 3s ease-out;

  &__social-list {
    display: flex;
    flex-direction: row;
    gap: 3rem;
  }

  a {
    background: transparent;
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    color: $color-grey-light;
    font-size: 4rem;
    transition: all .2s;

    &:hover {
      background: rgba($color-black, 0.3);
    }

    &.active {
      background: $color-grey-dark;
      color: $color-white;
    }
  }
}

@media screen and (max-width: 500px) {
  .nav {
    padding: 0 .5rem;
    bottom: 1.2rem;

    a {
      font-size: 3.3rem;
    }
  }
}