.project-list {
    display: flex;
    justify-content: space-around;
    flex-flow: wrap;
    margin: 0 auto;
    width: 88%;
}

.card {
    position: relative;
    margin-top: 12rem;

    &__face {
        width: 50rem;
        height: 27rem;
        transition: 0.5s;
    }

    &-top {
        position: relative;
        background: $color-primary;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        transform: translateY(18rem);
        height: 27rem;
        box-shadow: 0 -1rem 2rem rgba(0, 0, 0, 0.25);
        filter: hue-rotate(90deg) grayscale(90%);
        transition: all 1s;

        &__content {

            &--image {
                width: 100%;
                height: 100%;
            }

            &--title {
                margin: 1rem 0 0;
                padding: 0;
                color: $color-white;
            }
        }
    }

    &:hover &-top {
        background: $color-white;
        transform: translateY(0);
        filter: hue-rotate(0deg) grayscale(0%);
        box-shadow: 0 -1.5rem 3rem rgba(0, 0, 0, 0.15);
    }

    &:hover &-top__content {
        opacity: 1;
    }

    &:hover &-top__content--title {
        opacity: 0;
    }

    &-bottom {
        position: relative;
        background: $color-white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem;
        box-sizing: border-box;
        box-shadow: 0rem 0rem 3rem rgba(0, 0, 0, 0.35);
        transform: translateY(-9.5rem);

        &__content {
            width: 100%;
            text-align: left;

            &--title {
                margin: 1rem 0 1rem;
                padding: 0;
                text-align: center;
            }

            &--text {
                margin: 0 1rem;
                padding: 0;
                text-align: left;

                &:not(:last-child) {
                    margin-bottom: 1rem;
                }
            }

            &--stack {
                text-align: left;
                margin-left: 1rem;
            }

            &--button {

                svg {
                    font-size: 3rem;
                    margin: 1rem;
                    padding: .5rem;
                    &:hover {
                        background: $color-primary;
                        color: $color-white;
                        border-radius: 50%;
                    }
                }

            }
        }
    }

    &:hover &-bottom {
        transform: translateY(0);
    }

}

@media screen and (max-width: 800px) {
    .project-list {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .card {

        &__face {
            width: 35rem;
            height: 20rem;
            line-height: 1.3;
        }

        &-top {
            transform: translateY(9rem);
            height: 18rem;

            &__content {

                &--title {
                    margin: 1rem 0 0;
                    font-size: $default-font-size;
                }
            }
        }



        &-bottom {
            transform: translateY(-11rem);

            &__content {
                width: 30rem;

                &--title {
                    margin: 1rem 0 1rem;
                    font-size: $default-font-size;
                }

                &--text {
                    margin: 0 1rem;
                }

                &--button {
                    margin: 1rem .5rem 0;
                    font-size: $font-size-small;
                }
            }
        }

    }
}