.section-about {
  position: relative;
  height: 100vh;
  min-height:765px;
  background-image: linear-gradient(to bottom, $color-primary , $color-primary-light);
}

.section-skills {
  position: relative;
  background: $color-primary-light;
  margin: 0 auto;
}


.section-projects {
  position: relative;
  background-image: linear-gradient(to bottom, $color-primary-light, $color-primary);
  margin: 0 auto;
}

.section-contact {
  position: relative;
  background: $color-primary;
  padding-bottom: 15rem;

}

.screenreader-only {
  position:absolute;
  left:-10000px;
  top:auto;
  width: 1rem;
  height: 1rem;
  overflow:hidden;
}

@media screen and (max-width: 800px) {
  .section-contact  {
    padding-bottom: 6rem;
  }
}