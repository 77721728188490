.header {
  height: 100vh;
  min-height: 800px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
  background-image: linear-gradient(to bottom, grey, $color-primary), url(../../img/image2.jpg);
  background-blend-mode: screen;


  &__text-box {
    position: absolute;
    top: 30%;
    left: 10%;
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 80%;
  }
}

.fadeup-enter--1 {
  animation: fadeup 1s ease-out;
}

.fadeup-enter--2 {
  animation: fadeup 2s ease-out;
}

.fadeup-enter--3 {
  animation: fadeup 3s ease-out;
}